<template>
  <el-table :data="this.deviceData" v-infinite-scroll="deviceData" style="overflow: auto" height="400px">
    <el-table-column prop="jdwDeviceName" label="设备名称" min-width="120" show-overflow-tooltip> </el-table-column>
    <el-table-column prop="jdwSn" label="设备sn" min-width="100" show-overflow-tooltip></el-table-column>
    <el-table-column prop="jdwFailureType" label="故障类型" min-width="100" show-overflow-tooltip></el-table-column>
    <el-table-column prop="jdwFailureCause" label="故障原因" min-width="100" show-overflow-tooltip> </el-table-column>
    <el-table-column prop="jdwWarningTime" label="报警时间" min-width="100" show-overflow-tooltip></el-table-column>
    <el-table-column prop="jdwHandleStatus" label="处理结果" min-width="100"></el-table-column>
  </el-table>
</template>

<script>
  export default {
    props: ["deviceData"]
  };
</script>

<style>
</style>